<template>
  <v-container fluid>
    <!-- <v-row style="position: sticky; top: 64px; z-index: 2"> -->
    <v-row>
      <v-col cols="12">
        <PageHeader>
          <span slot="courses" class="font-weight-light">ASSIGN </span>
          LECTURERS
        </PageHeader>
        <v-card>
          <v-card-text>
            <v-form ref="courseHeader">
              <v-row>
                <v-col cols="12" lg="3">
                  <v-autocomplete
                    @change="courseDetails"
                    class="rounded-0"
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    @keyup="searchProgram"
                    clearable
                    hide-details="auto"
                    hide-selected
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                    :rules="[(v) => !!v || 'Program is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          <v-progress-circular
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                          <span class="ml-5">
                            Searching
                            <strong>Program...</strong>
                          </span>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Record(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="islevelLoaded"
                    :disabled="loadedProgram"
                    :items="itemsGrade"
                    class="rounded-0"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="Grading System"
                    hide-details="auto"
                    v-model="grade"
                    hide-selected
                    :rules="[(v) => !!v || 'Grading System is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Grading Systems...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_campus"
                    item-value="id"
                    item-text="campus_name"
                    @click.native="fetchCampus"
                    :loading="iscampusLoaded"
                    class="rounded-0"
                    hide-details="auto"
                    outlined
                    label="Campus"
                    v-model="campus"
                    :rules="[(v) => !!v || 'Campus is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Campus...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template></v-select
                  >
                </v-col>
                <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="iscalendarLoaded"
                    class="rounded-0"
                    v-model="calendar"
                    :items="basket_calendar"
                    item-text="academic_calendar"
                    item-value="id"
                    outlined
                    label="Calendar"
                    @click.native="fetchCalendar"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Calendar is required...']"
                    ><template #item="{ item }">
                      <span>
                        {{ `${item.academic_calendar}` }}

                        <b>{{ `${item.current ? " Current" : ""}` }} </b>
                      </span>
                    </template>
                    <template #selection="{ item }">
                      <span>
                        {{ `${item.academic_calendar}` }}

                        <b>{{ `${item.current ? " Current" : ""}` }} </b>
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Calendar(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    @click.native="fetchSemester"
                    v-model="semester"
                    :items="basket_semester"
                    item-value="id"
                    item-text="semester"
                    outlined
                    class="rounded-0"
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                    :rules="[(v) => !!v || 'Semester is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="islevelLoaded"
                    :disabled="loadedProgram"
                    :items="basket_level"
                    item-text="level"
                    class="rounded-0"
                    item-value="id"
                    @change="selectedLevel"
                    outlined
                    label="Level"
                    hide-details="auto"
                    v-model="level"
                    hide-selected
                    :rules="[(v) => !!v || 'Level is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Levels...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  v-if="ifIntake"
                  transition="slide-x=reverse-transition"
                >
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="isintakeLoaded"
                    :items="basket_intake"
                    item-text="intake"
                    item-value="id"
                    class="rounded-0"
                    outlined
                    label="Intake"
                    v-model="intake"
                    hide-details="auto"
                    hide-selected
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Intakes...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="12" lg="3">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    outlined
                    class="rounded-0"
                    label="Header Type"
                    v-model="type"
                    :items="headers"
                    item-text="state"
                    item-value="type"
                  ></v-select>
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <p v-show="showCourses" class="text-uppercase grey--text mt-5">COURSES</p>

    <v-row>
      <v-col>
        <v-card v-show="showCourses">
          <v-form ref="mountCourseForm">
            <v-slide-y-transition
              class="py-0"
              color="primary"
              group
              tag="v-list"
            >
              <template
                v-for="(course, index) in mountedCourses.currentCourses"
              >
                <v-divider
                  v-if="index !== 0"
                  :key="`${index}-divider`"
                ></v-divider>
                <v-list-item :key="`${index}`">
                  <v-row
                    justify="center"
                    align="center"
                    :key="`course ${index}`"
                    class="px-2"
                  >
                    <v-col lg="3">
                      <div class="caption grey--text pt-2">Course</div>
                      <div v-if="course.course">
                        {{ course.course.coursecode_coursename }}
                      </div>
                    </v-col>
                    <v-col class="text-center">
                      <div class="caption grey--text pt-2">Credit Hours</div>
                      <div v-if="course.course">
                        {{ course.course.credit_hours }}
                      </div>
                    </v-col>
                    <v-col
                      v-for="(session, index) in selectedSession"
                      :key="index"
                    >
                      <template
                        v-if="mountedCourses.courseSessionLecturer.length > 0"
                      >
                        <!-- <template
                          v-if="
                            mountedCourses.courseSessionLecturer.some(
                              (x) =>
                                x.session_id === session &&
                                x.course_id === course.course_id
                            )
                          "
                        > -->
                        <template
                          v-for="(
                            lecturer, lindex
                          ) in mountedCourses.courseSessionLecturer"
                        >
                          <div class="d-inline" :key="lindex">
                            <v-autocomplete
                              clearable
                              v-if="
                                lecturer.session_id === session &&
                                lecturer.course_id === course.course_id
                              "
                              class="my-1 rounded-0"
                              hide-details="auto"
                              :items="basket_lecturers"
                              v-model="lecturer.user_id"
                              @change="
                                getLecturer(course.course.id, session, $event)
                              "
                              :value="lecturer.user_id"
                              item-value="id"
                              item-text="title_name"
                              outlined
                              :label="
                                session == 1
                                  ? 'Morning'
                                  : session == 2
                                  ? 'Evening'
                                  : 'Weekend'
                              "
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    Loading
                                    <strong>Lecturers...</strong>
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </div>
                        </template>
                        <!-- </template> -->
                        <template
                          v-if="
                            !mountedCourses.courseSessionLecturer.some(
                              (x) =>
                                x.session_id === session &&
                                x.course_id === course.course_id
                            )
                          "
                        >
                          <v-autocomplete
                            class="my-1 rounded-0"
                            hide-details="auto"
                            :items="basket_lecturers"
                            @change="getLecturer(course.course.id, session, $event)"
                            item-value="id"
                            item-text="title_name"
                            outlined
                            :label="
                              session == 1
                                ? 'Morning'
                                : session == 2
                                ? 'Evening'
                                : 'Weekend'
                            "
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  Loading
                                  <strong>Lecturers...</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </template>
                      </template>
                      <template v-else>
                        <v-autocomplete
                          class="my-1 rounded-0"
                          hide-details="auto"
                          :items="basket_lecturers"
                          @change="getLecturer(course.course.id, session, $event)"
                          item-value="id"
                          item-text="title_name"
                          outlined
                          :label="
                            session == 1
                              ? 'Morning'
                              : session == 2
                              ? 'Evening'
                              : 'Weekend'
                          "
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                Loading
                                <strong>Lecturers...</strong>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-slide-y-transition>
            <v-divider></v-divider>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              color="primary"
              @click="scriptBtn"
              :disabled="
                !getters_abilities.includes('save_assigned_lecturer_access')
              "
            >
              assign lecturer(s)
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      bottom
      v-model="assigned"
      absolute
      :value="true"
      color="success"
    >
      Successfully Saved

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="assigned = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, provide, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance();

      const {
        getHodDeptProg,
        getSemester,
        getCalendar,
        getLevel,
        getIntake,
        getcourseheaderCampus,
        getCampus,
        getActiveStaff,
        saveassgingedLecturer,
        getSession,
        signOut,
      } = useActions([
        "getHodDeptProg",
        "getSemester",
        "getCalendar",
        "getLevel",
        "getIntake",
        "getSession",
        "getCampus",
        "saveMountedCourses",
        "getcourseheaderCampus",
        "getActiveStaff",
        "saveassgingedLecturer",
        "getSession",
        "signOut",
      ]);

      const {
        getters_hoddeptprog,
        getters_semester,
        getters_calendar,
        getters_level,
        getters_intake,
        /* getters_program_details, */
        getters_campus,
        getters_course_header_campus,
        getters_active_staff,
        getters_session,
        getters_abilities,
        user,
      } = useGetters([
        "getters_courses",
        "getters_hoddeptprog",
        "getters_semester",
        "getters_calendar",
        "getters_level",
        "getters_intake",
        "getters_program_details",
        "getters_header_exists",
        "getters_campus",
        "getters_course_header_campus",
        "getters_active_staff",
        "getters_session",
        "getters_abilities",
        "user",
      ]);

      const courseHeader = reactive({
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        iscalendarLoaded: false,
        issemesterLoaded: false,
        islevelLoaded: false,
        isintakeLoaded: false,
        iscampusLoaded: false,
        issessionLoaded: false,
        loadedLevel: false,
        loadedProgram: true,
        program: "",
        calendar: "",
        semester: "",
        level: "",
        intake: "",
        campus: "",
        grade: "",
        type: null,
        basket_calendar: [],
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        basket_lecturers: [],
        basket_session: [],
        ifIntake: false,
        itemsProgram: [],
        itemsGrade: [],
        loadProgram: false,
        get_program_info: null,
        headers: [
          { state: "Main", type: 0 },
          { state: "Resit", type: 1 },
        ],
      });

      const {
        get_program_info,
        msgHeader,
        msgBody,
        msgIcon,
        actionResponse,
        loadedProgram,
        itemsProgram,
        loadProgram,
        basket_calendar,
        basket_semester,
        basket_level,
        basket_campus,
        basket_intake,
        basket_lecturers,
        basket_session,
        ifIntake,
        program,
        calendar,
        semester,
        campus,
        level,
        intake,
        iscalendarLoaded,
        issemesterLoaded,
        islevelLoaded,
        isintakeLoaded,
        iscampusLoaded,
        issessionLoaded,
        itemsGrade,
        grade,
        // type,
      } = toRefs(courseHeader);

      const mountCourseForm = reactive({
        mountedCourses: [],
        loadCourse: false,
        showCourses: false,
        errorMsgs: [],
        lecturerBasket: [],
      });

      const absolute = ref(true);
      const overlay = ref(false);
      const dialog = ref(false);
      const assigned = ref(false);
      const selectedSession = ref([1, 2, 3]);
      const color = ref("");

      const {
        mountedCourses,
        showCourses,
        lecturerBasket,
        //selectedSession,
      } = toRefs(mountCourseForm);

      watch(
        () => [
          calendar.value,
          semester.value,
          level.value,
          intake.value,
          program.value,
          campus.value,
          grade.value,
          // type.value,
        ],
        () => {
          if (
            calendar.value &&
            semester.value &&
            level.value &&
            intake.value &&
            program.value &&
            campus.value &&
            grade.value
            // (type.value == 0 || type.value == 1)
            //	selectedSession.value.length > 0
          ) {
            let header = {
              calendar_id: calendar.value,
              semester_id: semester.value,
              level_id: level.value,
              intake_id: intake.value,
              program_id: program.value,
              campus_id: campus.value,
              grade_id: grade.value,
              // header_type: type.value,
            };
            overlay.value = true;
            actionResponse.value = false;
            getcourseheaderCampus(header)
              .then(() => {
                showCourses.value = false;
                overlay.value = false;

                if (!getters_course_header_campus.value) {
                  actionResponse.value = true;
                  msgBody.value = "NO ASSIGNMENT AVAILABLE";
                  msgHeader.value = "Information";
                  msgIcon.value = "mdi-information";
                  color.value = "info";
                } else {
                  showCourses.value = true;
                  actionResponse.value = false;
                  mountedCourses.value = getters_course_header_campus.value;
                  return;
                }
              })
              .catch((e) => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                showCourses.value = false;
                switch (e.response.status) {
                  case 422:
                    msgBody.value = e.response.data.message;
                    break;

                  default:
                    msgBody.value =
                      "Try Again Later Or Call The System Administrator";
                    break;
                }
              });
          } else {
            showCourses.value = false;
          }
        }
      );

      provide("color", color);

      const searchProgram = debounce(() => {
        if (program.value != null || searchProgram.value != "undefined") {
          if (itemsProgram.value.length > 0) return;
          loadProgram.value = true;

          getHodDeptProg()
            .then(() => {
              itemsProgram.value = getters_hoddeptprog.value;
            })

            .finally(() => (loadProgram.value = false));
        }
      });

      getActiveStaff().then(() => {
        basket_lecturers.value = getters_active_staff.value;
      });

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      const fetchIntake = () => {
        isintakeLoaded.value = true;
        if (basket_intake.value.length > 0) {
          basket_intake.value = getters_intake.value;
          isintakeLoaded.value = false;
          return;
        }

        getIntake();
        basket_intake.value = getters_intake.value;
        isintakeLoaded.value = false;
      };

      fetchIntake();

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (basket_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const fetchCampus = () => {
        iscampusLoaded.value = true;
        if (basket_campus.value.length > 0) {
          iscampusLoaded.value = false;
          basket_campus.value = [...basket_campus.value];
          return;
        }

        getCampus()
          .then(() => {
            if (getters_abilities.value.includes("all_campus_access")) {
              basket_campus.value = getters_campus.value;
            } else {
              basket_campus.value = getters_campus.value.filter(
                (campus) => campus.id === parseInt(user.value.campus[0].id)
              );
            }
          })
          .finally(() => (iscampusLoaded.value = false));
      };

      const fetchSession = () => {
        issessionLoaded.value = true;
        if (getters_session.value.length > 0) {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
          return;
        }

        getSession().then(() => {
          basket_session.value = getters_session.value;
          issessionLoaded.value = false;
        });
      };

      fetchSession();

      const selectedLevel = (level) => {
        intake.value = null;
        var get_level = getters_level.value.find((item) => item.id === level);
        if (get_program_info.value.program_state && get_level.level === 300) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() != "all" && item.current
          );
          ifIntake.value = true;
        } else if (
          !get_program_info.value.program_state &&
          get_level.level === 300
        ) {
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() == "all"
          );
          ifIntake.value = false;
          intake.value = basket_intake.value[0].id;
        } else if (get_level.level === 200 || get_level.level === 400) {
          ifIntake.value = false;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() === "all"
          );
          intake.value = basket_intake.value[0].id;
        } else {
          ifIntake.value = true;
          basket_intake.value = getters_intake.value.filter(
            (item) => item.intake.toLowerCase() !== "all" && item.current
          );
        }
      };

      const courseDetails = (i) => {
        //	getProgramDetails(i).finally(() => {
        if (!i) return;
        islevelLoaded.value = true;
        loadedProgram.value = true;
        ifIntake.value = false;
        intake.value = "";
        level.value = "";
        get_program_info.value = getters_hoddeptprog.value.find(
          (item) => item.id === i
        );
        itemsGrade.value = get_program_info.value.grades;

        getLevel().finally(() => {
          basket_level.value = getters_level.value;

          if (get_program_info.value.program_state) {
            basket_level.value = getters_level.value.filter(
              (item) => item.level >= 300
            );
          } else {
            basket_level.value = getters_level.value;
          }
          islevelLoaded.value = false;
        });

        loadedProgram.value = false;
        //});
      };

      const getLecturer = (course, session, lecturer_id) => {
        console.log(course);
        let arr = {
          course_id: course,
          campus_id: campus.value,
          session_id: session,
          user_id: lecturer_id,
          grade_id: grade.value,
          program_id: program.value,
          calendar_id: calendar.value,
          semester_id: semester.value,
          intake_id: intake.value,
          level_id: level.value,
          is_published: 0,
        };

        let ifExists = lecturerBasket.value.some(
          (element) => element.course === course && element.session === session
        );

        if (!ifExists) {
          lecturerBasket.value.push(arr);
        } else {
          lecturerBasket.value.forEach((element, i) => {
            if (element.course === course && element.session === session) {
              lecturerBasket.value.splice(i, 1, arr);
            }
          });
        }
      };

      const scriptBtn = () => {
        overlay.value = true;
        actionResponse.value = false;

        if (vm.proxy.$refs.mountCourseForm.validate()) {
          let arr = {
            campus_id: campus.value,
            grade_id: grade.value,
            program_id: program.value,
            calendar_id: calendar.value,
            semester_id: semester.value,
            intake_id: intake.value,
            level_id: level.value,
          };
          saveassgingedLecturer({
            basket: lecturerBasket.value,
            header_info: arr,
          })
            .then(() => {
              overlay.value = false;
              lecturerBasket.value = [];
              vm.proxy.$refs.mountCourseForm.reset();
              vm.proxy.$refs.courseHeader.reset();
              showCourses.value = false;
              assigned.value = true;
            })
            .catch((e) => {
              overlay.value = false;
              actionResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              vm.proxy.$refs.facultyForm.reset();
              switch (e.response.status) {
                case 403:
                  msgBody.value = e.response.data.message;

                  break;
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                default:
                  msgBody.value =
                    "YOUR REQUIRED COULD NOT BE PROCESSED. TRY AGAIN LATER OR CALL THE SYSTEM ADMINISTRATOR";
                  break;
              }
            });
        }
      };

      return {
        ...toRefs(mountCourseForm),
        ...toRefs(courseHeader),
        fetchCalendar,
        courseDetails,
        fetchSemester,
        fetchCampus,
        selectedLevel,
        absolute,
        overlay,
        getters_course_header_campus,
        actionResponse,
        searchProgram,
        //	fetchLecturers,
        getLecturer,
        scriptBtn,
        fetchSession,
        dialog,
        assigned,
        selectedSession,
        getters_abilities,
      };
    },
  };
</script>
<style scoped>
  .border:hover {
    border-left: 5px solid #1976d2 !important;
    cursor: pointer !important;
  }
</style>
